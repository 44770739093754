<template>
  <div style="max-width:1000px;">
    <div class="maintitle">添加商品</div>
    <el-divider></el-divider>
    <el-form ref="form" :model="form" label-width="80px" :rules="rules">
      <el-form-item label="商品名称" prop="name">
        <el-input v-model="form.name" minlength="4" maxlength="20" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="商品图片" prop="pic" class="text-left">
        <el-upload
          class="upload-demo"
          drag
          action="https://api.yijianhuishou.com/jiameng/jifengoods/uppic"
          :multiple="false"
          :data="uploadData"
          :show-file-list="false"
          :on-success="upload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，375*375像素</div>
        </el-upload>
      </el-form-item>
      <el-form-item label class="text-left">
        <img :src="HOST+form.pic" alt />
        <el-input style="display:none" v-model="form.pic"></el-input>
      </el-form-item>
      <el-form-item label="简介" class="text-left">
        <el-input v-model="form.detail" placeholder="请输入商品简介" maxlength="30" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="积分数" class="text-left">
        <el-input v-model="form.jifen" placeholder="请输入兑换需要的积分数"></el-input>
      </el-form-item>
      <el-form-item label="数量" class="text-left">
        <el-input v-model="form.num" placeholder="请输入商品总数"></el-input>
      </el-form-item>
    </el-form>
    <el-button @click="add">添加</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        pic: "",
        detail: '',
        jifen:'',
        num:''
      },
      rules: {},
      uploadData: {
        jiameng_id: sessionStorage.getItem("jiameng_id"),
        jiameng_token: sessionStorage.getItem("jiameng_token")
      }
    };
  },
  methods: {
    add: function() {
      console.log(this.form);
      this.$axios({
        url: this.HOST + "/jiameng/jifengoods/addGoods",
        method: "post",
        data: {
          info: this.form
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.$router.push("/goods");
        } else if (res.data.status == 0) {
          this.$message({
            type: "error",
            message: res.data.info
          });
        }
      });
    },
    upload(response) {
      if (response.status == 1) {
        this.form.pic = response.pic;
      } else {
        this.$message({
          type: "error",
          message: response.info
        });
      }
    }
  },
  computed: {},
  created: function() {
  }
};
</script>

<style>
</style>